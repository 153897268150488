<template>
    <router-view id="app" class="app1"></router-view>
</template>
<script>
export default {
    name: "App"
};
</script>
<style>
.app1 {
    -webkit-text-size-adjust: none;
}
</style>
