import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const App = () => import("@views/app");

const About = () => import("@views/about");

const Document = () => import("@views/document");

const Doc = () => import("@views/doc");

const Develop = () => import("@views/develop");

const Search = () => import("@views/search");

const Question = () => import("@views/question");

const Detail = () => import("@views/detail");

const Devdoc = () => import("@views/devdoc");

const Products = () => import("@views/products");

const ProDetail = () => import("@views/proDetail");

const routes = [
    {
        name: "app",
        path: "/app",
        component: App
    },
    {
        name: "about",
        path: "/about",
        component: About
    },
    {
        name: "document",
        path: "/document",
        component: Document,
        children: []
    },
    {
        name: "doc",
        path: "/doc",
        component: Doc,
        children: []
    },
    {
        name: "develop",
        path: "/develop",
        component: Develop,
        children: []
    },
    {
        name: "search",
        path: "/search",
        component: Search,
        children: []
    },
    {
        name: "question",
        path: "/question",
        component: Question,
        children: []
    },
    {
        name: "detail",
        path: "/detail",
        component: Detail,
        children: []
    },
    {
        name: "devdoc",
        path: "/devdoc",
        component: Devdoc,
        children: []
    },
    {
        name: "products",
        path: "/products",
        component: Products,
        children: []
    },
    {
        name: "proDetail",
        path: "/proDetail",
        component: ProDetail,
        children: []
    },
    {
        path: "*",
        redirect: "/app"
    }
];

export default new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active"
});
