import Vue from "vue";
import App from "./app";
import router from "./router";
import BaiduMap from "vue-baidu-map";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Components from "@components/index.js";
import "@style/index.less";
import axios from "axios";
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
// axios.defaults.baseURL = "https://idmp_dev.xintiangui.com/";
axios.defaults.baseURL = "https://tiangong.wif.ink/";

Vue.prototype.axios = axios;
Vue.use(ElementUI);
Vue.use(Components);

Vue.use(BaiduMap, {
    ak: "Icz45uIFywWNxDW1ctDqXqrTtCk1vipn"
});

Vue.config.productionTip = false;

new Vue({
    el: "#app",
    router,
    render: h => h(App)
});
